/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';

import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';
import { getUserRole } from '../../../../util/helper';

import Modal from '../../../../components/Modal/Modal';
import UserSendInvitatinPage from '../../../UserSendInvitation/UserSendInvitatinPage';

const onManageDisableScrolling = () => {
  // console.log("onManageDisableScrolling");
}

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const [isOpen, setIsOpen] = useState(false);


  const user = ensureCurrentUser(currentUser);
  const userRole = currentUser && getUserRole(currentUser);

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          {userRole == "photographer" && <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>}
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {userRole == "photographer" &&
          // <NamedLink  className={classNames(css.navigationLink, currentPageClass('UserSendInvitation'))} name="UserSendInvitation">
          <span  className={classNames(css.navigationLink, currentPageClass('UserSendInvitation'))} onClick={() => setIsOpen(true)}>
            <FormattedMessage id="TopbarMobileMenu.UserSendInvitation" />
          </span>
          // </NamedLink>
        }
        {userRole == "photographer" &&
          <NamedLink className={classNames(css.navigationLink, currentPageClass('ShowCustomersPage'))} name="ShowCustomersPage">
            <FormattedMessage id="TopbarMobileMenu.ShowCustomersPage" />
          </NamedLink>
        }
        {userRole == "photographer" && <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        > 
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>} 
          {userRole == "customer" && <NamedLink
            className={classNames(css.navigationLink, currentPageClass('PurchaseImagePage'))}
            name="PurchaseImagePage"
          >
           <FormattedMessage id="TopbarDesktop.yourImageGalleryLink" />
            </NamedLink>}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <div className={css.spacer} />
      </div>
      {/* {isAuthenticated && userRole == "customer" && (
        <div className={css.banner}>
          Hello! Thank you for your interest in sihmo. Please wait for your photo to be uploaded. You will get an email when photographer uploaded the photo.
        </div>
      )} */}

      <Modal
        id="userSendInvitationMobile"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <UserSendInvitatinPage />
      </Modal>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
